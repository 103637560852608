import { Container } from '@mentalgrowth/ui-kit-web';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { object, string } from 'yup';

import { RequestError } from 'src/api/api';
import PresentBackground from 'src/assets/images/new-plan-paywall/present-discount-timer-finished.svg';
import AlertWithCloseButton from 'src/components/common/modal/alert-with-close-button/alert-with-close-button';
import Alert from 'src/components/common/modal/alert/alert';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import { UiKitInput } from 'src/components/common/ui-kit-input';
import { UiKitLoadingButton } from 'src/components/common/ui-kit-loading-button';
import { getRandomEmail, isDevelopment } from 'src/utils';

import { FormattedMessage, useIntl } from '@features/intl';

import axios from 'axios';
import MailExistImage from '../../../../assets/icons/mail.svg';
import styles from './EmailForm.module.scss';

const FORM_INITIAL_VALUES = {
  email: '',
};

type TpEmailFormProps = {
  containerClassName?: string;
  children?: React.ReactNode;
  handleSubmitExtended?: ({
    email,
    shouldGoToNext,
  }: {
    email: string;
    shouldGoToNext?: boolean;
  }) => void;
  handleExistEmail?: () => void;
  submitButtonText?: string;
};

const axiosUtils = axios.create({
  baseURL: 'https://proxy-utils.mentalgrowth.app/',
  timeout: 30_000,
  headers: {
    'Content-type': 'application/json',
  },
});

export const EmailForm = observer((props: TpEmailFormProps) => {
  const {
    containerClassName,
    children,
    handleSubmitExtended,
    handleExistEmail,
    submitButtonText,
  } = props;
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [disableButtonAfterSubmit, setDisableButtonAfterSubmit] =
    useState(false);
  const [showExistEmailModal, setShowExistEmailModal] = useState(false);
  const [loadingEmailVerify, setLoadingEmailVerify] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState('');

  const intl = useIntl();

  const FORM_SCHEMA = object({
    email: string()
      .trim()
      .required(
        intl.formatMessage({
          asString: true,
          id: 'Onboarding.Email.Сlue.Error',
          defaultMessage: 'Something went wrong',
        }) as string,
      )
      .email(
        intl.formatMessage({
          asString: true,
          id: 'Onboarding.Email.Сlue.Error',
          defaultMessage: 'Something went wrong',
        }) as string,
      ),
  });

  const { authStore } = useStores();
  const { variant } = authStore;

  const {
    values,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    isValid,
    isSubmitting,
    setFieldError,
    setFieldValue,
  } = useFormik({
    validateOnMount: true,
    initialValues: FORM_INITIAL_VALUES,
    validationSchema: FORM_SCHEMA,
    onSubmit: (values) => {
      if (variant === 'emailtraf') {
        onSubmitEmailVerify(values.email).then((r) => r);
      } else {
        onSubmitCallback(values.email).then((r) => r);
      }
    },
  });

  const onSubmitCallback = useCallback(
    async (value: string) => {
      try {
        await authStore.register({
          email: value,
        });
      } catch (error) {
        if (error instanceof RequestError && error.name === 'AbortError') {
          return;
        }
        if (
          error instanceof RequestError &&
          error.statusCode === 400 &&
          error.data === 'User with this email already exists'
        ) {
          setFieldError('email', 'This email is already in use');
          setShowExistEmailModal(true);

          handleExistEmail && handleExistEmail();
          return;
        }
        throw error;
      }

      handleSubmitExtended &&
        handleSubmitExtended({
          email: values.email,
          shouldGoToNext: variant !== 'emailtraf',
        });
    },
    [
      authStore,
      handleExistEmail,
      handleSubmitExtended,
      setFieldError,
      values.email,
      variant,
    ],
  );

  const onSubmitEmailVerify = useCallback(
    async (value: string) => {
      try {
        setLoadingEmailVerify(true);
        const response = await axiosUtils.post('verify_email', {
          email: value,
        });
        if (
          response &&
          (response.data[0] === 'ok' ||
            response.data[0] === 'antispam_system' ||
            response.data[0] === 'accept_all' ||
            response.data[0] === 'unknown' ||
            response.data[0] === 'ok_for_all' ||
            response.data[0] === 'disposable')
        ) {
          setShowVerifyEmailModal(true);
          setVerifyMessage('ok');
          setLoadingEmailVerify(false);
          await onSubmitCallback(value);
        } else {
          setVerifyMessage('Incorrect email');
          setLoadingEmailVerify(false);
        }
      } catch (error) {
        setLoadingEmailVerify(false);
        console.log('Error', error);
        await onSubmitCallback(value);
      }
    },
    [onSubmitCallback],
  );

  const handleChangeEmailField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('email', event.target.value);
    },
    [setFieldValue],
  );

  const handleRandomGenerate = useCallback(() => {
    setFieldValue('email', getRandomEmail());
  }, [setFieldValue]);

  const placeholderMessage = intl.formatMessage({
    asString: true,
    id: 'Onboarding.Email.Clue',
    defaultMessage: 'Enter your email to get your plan',
  }) as string;

  const currentSubmitButtonText = useMemo(
    () =>
      submitButtonText ?? (
        <FormattedMessage
          id="Onboarding.Email.Button"
          defaultMessage="See my plan"
        />
      ),
    [submitButtonText],
  );

  return (
    <form onSubmit={handleSubmit} className={containerClassName}>
      <div className={styles.emailGroup}>
        <UiKitInput
          fullWidth
          type="email"
          autoComplete="email"
          placeholder={placeholderMessage}
          name="email"
          value={values.email}
          onChange={handleChangeEmailField}
          onBlur={handleBlur}
          isInvalid={
            (touched.email && errors.email != null) ||
            verifyMessage === 'Incorrect email'
          }
          errorText={errors.email}
          errorTextClassName={styles.errorText}
        />
        {isDevelopment && (
          <UiKitButton
            data-testid="random-email"
            className={styles.randomButton}
            onClick={handleRandomGenerate}
          >
            random
          </UiKitButton>
        )}
        {verifyMessage === 'Incorrect email' && (
          <div className={styles.errorMessage} data-testid="incorrect-email">
            Incorrect e-mail, please check and try again
          </div>
        )}
      </div>

      {children}

      {variant === 'emailtraf' ? (
        <UiKitLoadingButton
          type="submit"
          disabled={!isValid || disableButtonAfterSubmit || loadingEmailVerify}
          loading={loadingEmailVerify}
          fullWidth
          data-testid="submit"
        >
          {currentSubmitButtonText}
        </UiKitLoadingButton>
      ) : (
        <UiKitLoadingButton
          type="submit"
          disabled={
            !isValid ||
            isSubmitting ||
            disableButtonAfterSubmit ||
            loadingEmailVerify
          }
          loading={isSubmitting || loadingEmailVerify}
          fullWidth
          data-testid="submit"
        >
          {currentSubmitButtonText}
        </UiKitLoadingButton>
      )}

      {variant === 'emailtraf' && (
        <Alert
          contentClassName={styles.verifyModal}
          show={showVerifyEmailModal}
          image={<PresentBackground className={styles.salesImage} />}
        >
          <Container className={styles.verifyModalContainer}>
            <div className="double-grow">
              <h2 className={styles.verifyModalTitle}>Check Your Inbox!</h2>
              <p className={styles.verifyModalDescription}>
                Click in the email and <b>Get your FREE Pilates Guide</b> after
                confirmation!
              </p>
              <p className={styles.verifyModalWarning}>
                <b>Warning</b>: <u>Check your SPAM folder!</u>
              </p>
            </div>
            <UiKitButton
              className={styles.verifyModalButton}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                setFieldValue('email', '');
                setShowVerifyEmailModal(false);
                setDisableButtonAfterSubmit(false);
              }}
            >
              Use a different e-mail
            </UiKitButton>
          </Container>
        </Alert>
      )}

      <AlertWithCloseButton
        show={showExistEmailModal}
        image={<MailExistImage />}
        title="Existing email"
        description="This email was already used to register. We’ve sent you the e-mail with instructions, please check your inbox to proceed with the current e-mail or use another to register."
        onHide={() => setShowExistEmailModal(false)}
        buttonVariant="accent-2"
        closeButtonText="Got it"
      />
    </form>
  );
});
