// Modified fork of https://github.com/sergiodxa/flagged lib
import { FEATURE_FLAGS } from '@features/FeatureToggle/FeatureFlagProvider';

import { ComponentType, ReactNode, createContext, useContext } from 'react';

type FeatureFlag = (typeof FEATURE_FLAGS)[number];

type FeatureGroup = Record<FeatureFlag, boolean> | { [key: string]: boolean };

let FeatureFlagsContext = createContext<FeatureGroup>({});

function transformFlags(features: FeatureGroup): FeatureGroup {
  if (!Array.isArray(features)) return features;
  return Object.fromEntries(features.map((feature) => [feature, true]));
}

function mergeFeatures(a: FeatureGroup, b: FeatureGroup): FeatureGroup {
  return { ...a, ...b };
}

export function FlagsProvider({
  features,
  children,
}: {
  features?: FeatureGroup;
  children: ReactNode;
}) {
  let currentFeatures = useFeatures();
  return (
    <FeatureFlagsContext.Provider
      value={mergeFeatures(
        transformFlags(currentFeatures),
        transformFlags(features || {}),
      )}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatures(): FeatureGroup {
  return useContext(FeatureFlagsContext);
}

export function useFeature(name: FeatureFlag): boolean | FeatureGroup {
  let features = useFeatures();
  if (Array.isArray(features)) return features.includes(name);
  if (typeof features[name] === 'boolean') return features[name];

  let featureGroup: FeatureGroup | boolean = structuredClone(features);
  for (let featureName of name.split('/')) {
    if (typeof featureGroup === 'boolean') return featureGroup;
    if (featureGroup[featureName as FeatureFlag] === undefined) return false;
    featureGroup = featureGroup[featureName as FeatureFlag];
  }

  return featureGroup;
}

export function Feature({
  name,
  children,
  render = children,
}: {
  name: string;
  children?: ReactNode | ((hasFeature: boolean | FeatureGroup) => JSX.Element);
  render?: ReactNode | ((hasFeature: boolean | FeatureGroup) => JSX.Element);
}) {
  let hasFeature = useFeature(name as FeatureFlag);
  if (typeof render === 'function') return render(hasFeature);
  if (!hasFeature) return null;
  return <>{render}</>;
}

export function withFeature<Props extends object>(featureName: string) {
  return (Component: ComponentType<Props>) => {
    function WithFeature(props: Props) {
      return (
        <Feature name={featureName}>
          <Component {...props} />
        </Feature>
      );
    }

    WithFeature.displayName = `WithFeature(${Component.displayName || Component.name})`;

    return WithFeature;
  };
}
