import { Image } from 'src/components/common/Image';
import styles from './LossPlanSteps.module.scss';

const TOOLTIP_DATA = [
  {
    text: '117 exercises adapted to your fitness level',
    subtext: 'on 28 days ',
    image: 'dumbbell.webp',
    color: '#C8D4D7',
  },
  {
    text: '8 different meal plans to choose from',
    subtext: 'Over 100+ recipes',
    image: 'spodt-bottle.webp',
    color: '#E7DDF9',
  },
  {
    text: 'A ready-to-use workout calendar',
    subtext: 'fitted to your lifestyle',
    image: 'calendar-prepaywall.webp',
    color: '#F3E3E2',
  },
];

export const LossPlanSteps = ({ colorData }: { colorData?: string[] }) => {
  const modifiedTooltipData = TOOLTIP_DATA.map((item, index) => ({
    ...item,
    color: colorData?.[index] || item.color,
  }));

  return (
    <div className={styles.tooltip}>
      {modifiedTooltipData.map((item) => (
        <div
          key={item.text}
          className={styles.tooltipItem}
          style={{ background: item.color }}
        >
          <div>
            <p className={styles.tooltipText}>{item.text}</p>
            <span className={styles.tooltipSubtext}>{item.subtext}</span>
          </div>
          <Image src={item.image} alt="" width={112} height={98} />
        </div>
      ))}
    </div>
  );
};
