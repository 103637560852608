import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Slider from 'react-slick';
import ChevronLeft from 'src/assets/icons/chevron-left.svg';
import ChevronRight from 'src/assets/icons/chevron-right.svg';
import { Image } from 'src/components/common/Image';
import CloseButton from 'src/components/common/close-button/close-button';
import Alert from 'src/components/common/modal/alert/alert';

import { observer } from 'mobx-react-lite';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import styles from './reviews-slider.module.scss';

type Slide = {
  imageUrlBefore: string;
  imageUrlAfter: string;
  name: string;
  weightLoss: string;
  description: string;
};

const slides: Slide[] = [
  {
    imageUrlBefore: 'sarah-before-review.webp',
    imageUrlAfter: 'sarah-after-review.webp',
    name: 'Sarah',
    weightLoss: '-14',
    description:
      'Quick, productive workouts are what I need and have received. I’ve been using this app since Feb. 2023 and I’ve lost 30 pounds and reached my goal weight! I started with the 28-day Pilates challenge.',
  },
  {
    imageUrlBefore: 'diana-before-review.webp',
    imageUrlAfter: 'diana-after-review.webp',
    name: 'Diana',
    weightLoss: '-5',
    description:
      'I’m starting my second month with Youth and now I cannot live without the Wall Pilates. In less than a month, looking at the mirror and see that it makes a huge impact on my body shape.',
  },
  {
    imageUrlBefore: 'shy-before-review.webp',
    imageUrlAfter: 'shy-after-review.webp',
    name: 'Shy',
    weightLoss: '-15',
    description:
      'I absolutely love this app doing Pilates as it is helping me shed inches and pounds. I never thought I’d like doing Pilates because it isn’t high impact. It does work ❤❤',
  },
  {
    imageUrlBefore: 'eun-before-review.webp',
    imageUrlAfter: 'eun-after-review.webp',
    name: 'Eun',
    weightLoss: '-5',
    description:
      'It helps you track everything you need when you want to help yourself lowering weight or keeping it, from water to food and calories and many other, adding to that the 1 to 1 conversations are super helpful the trainings help so much it’s extremely good.',
  },
  {
    imageUrlBefore: 'nguyet-before-review.webp',
    imageUrlAfter: 'nguyet-after-review.webp',
    name: 'Nguyet',
    weightLoss: '-6',
    description:
      'Growing up I never battled with my weight. Eating whatever I wanted without seeing any negative consequences, those bad habits led to developing bad habits. However, those bad habits quickly caught up with me after my hysterectomy. I tried one diet after another with little to no success. YOUTH not only has helped me form good eating habits, but they have educated me along the way. I feel empowered now! I have the tools I need to continue to reach my health and weight goals! The app is easy to use and the constant coaching is tremendously motivating. I love the challenges! The 21 day no sugar challenge combined with intermittent fasting helped me to lose 13lbs! I feel great and can’t wait to see where my weight loss journey takes me!',
  },
];

const NextArrow = (props: {
  className?: string;
  style?: any;
  onClick?: () => void;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'flex', background: 'white' }}
      onClick={onClick}
    >
      <ChevronRight />
    </div>
  );
};

const PrevArrow = (props: {
  className?: string;
  style?: any;
  onClick?: () => void;
}) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'flex', background: 'white' }}
      onClick={onClick}
    >
      <ChevronLeft />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow className={styles.prevArrow} />,
  nextArrow: <NextArrow className={styles.nextArrow} />,
};

const Slide = observer(
  ({
    slide,
    isFullDescription,
    handleReadMore,
  }: {
    slide: Slide;
    isFullDescription: boolean;
    handleReadMore: (slide: Slide) => void;
  }) => {
    const {
      quizStore: { countryCode },
    } = useStores();
    const CODE_DATA = ['US', 'CA', 'NZ', 'AU', 'GB'];
    const weightUnit = CODE_DATA.includes(countryCode ? countryCode : 'US')
      ? 'lbs'
      : 'kg';

    return (
      <div className={styles.slide}>
        <div className={styles.beforeAfterContainer}>
          <div className={styles.imageWrapper}>
            <Image
              src={slide.imageUrlBefore}
              alt={slide.name}
              fill
              style={{ objectFit: 'cover' }}
              className={styles.sliderImage}
            />
          </div>
          <div className={styles.imageWrapper}>
            <Image
              fill
              style={{ objectFit: 'cover' }}
              src={slide.imageUrlAfter}
              alt={slide.name}
              className={styles.sliderImage}
            />
          </div>

          <div className={styles.label}>
            {slide.name},{' '}
            <em>
              {slide.weightLoss} {weightUnit}
            </em>
          </div>
        </div>
        <div
          className={classNames(
            isFullDescription ? styles.fullDescription : styles.description,
          )}
        >
          {slide.description}
        </div>
        {!isFullDescription && (
          <div
            className={styles.readMore}
            onClick={() => handleReadMore(slide)}
          >
            Read more
          </div>
        )}
      </div>
    );
  },
);

Slide.displayName = 'Slide';

export const ReviewsSlider: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState<Slide | null>(null);

  const handleReadMore = useCallback((slide: Slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  }, []);

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        {slides.map((slide) => (
          <Slide
            handleReadMore={handleReadMore}
            key={slide.name}
            slide={slide}
            isFullDescription={false}
          />
        ))}
      </Slider>

      <Alert
        show={isModalOpen}
        className={styles.modalContainer}
        onHide={() => setModalOpen(false)}
      >
        <CloseButton
          className={styles.closeButton}
          onClick={() => setModalOpen(false)}
        />

        {selectedSlide && (
          <Slide
            handleReadMore={handleReadMore}
            key={selectedSlide.name}
            slide={selectedSlide}
            isFullDescription
          />
        )}
      </Alert>
    </div>
  );
};
