import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo } from 'react';

import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

import { FlagsProvider } from './FlaggedUtil';

import { INTRO_OFFER_TEST_NAME } from 'src/constants/analytics';

export const FEATURE_FLAGS = ['introOffer'] as const;

const startsWithB = (inputString: any): boolean => /^B/.test(inputString || '');

export const FeatureFlagProvider = observer(
  ({ children }: { children: ReactNode }) => {
    const {
      quizStore,
      analyticsStore: { getCaseNameByTestName },
      authStore: { variant },
    } = useStores();

    const isIntroOfferEnabled =
      (startsWithB(getCaseNameByTestName(INTRO_OFFER_TEST_NAME)) ||
        variant === 'variant17') &&
      quizStore.countryCode === 'US';

    const features = useMemo(
      (): Record<(typeof FEATURE_FLAGS)[number], boolean> => ({
        introOffer: isIntroOfferEnabled,
      }),
      [isIntroOfferEnabled],
    );

    return <FlagsProvider features={features}>{children}</FlagsProvider>;
  },
);
