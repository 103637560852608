import classNames from 'classnames';

import { PolicyLink, RefundLink, TermsLink } from 'src/widgets/policy';

import { FormattedMessage } from '@features/intl';

import styles from './policy.module.scss';

export interface Props {
  className?: string;
  withPrefix?: boolean;
  place?: 'paywall' | 'checkout' | 'burger_menu' | 'email_modal' | 'upsale';
}

export const Policy = ({
  className,
  withPrefix = false,
  place = 'paywall',
}: Props) => (
  <span
    className={classNames(
      styles.container,
      !withPrefix && styles.containerWithWordSpacing,
      className,
    )}
  >
    <FormattedMessage
      defaultMessage="By continuing, you accept the {terms}, {privacy} and {refund}."
      id="Onboarding.Paywall.Main.Contact.Terms"
      values={{
        privacy: <PolicyLink place={place} />,
        terms: <TermsLink place={place} />,
        refund: <RefundLink place={place} />,
      }}
    />
  </span>
);
