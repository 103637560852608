import { ComponentProps } from 'react';

import Times from '../../../assets/icons/times.svg';
import CircleButton from '../circle-button/circle-button';

const CloseButton = (
  props: Omit<ComponentProps<typeof CircleButton>, 'children'>,
) => (
  <CircleButton {...props} data-testid="close-modal">
    <Times />
  </CircleButton>
);

export default CloseButton;
