import { Image } from 'src/components/common/Image';
import styles from './LongFirstScreenHeaderSteps.module.scss';

const DATA_STEPS = [
  {
    image: 'emoji-clock.webp',
    text: 'Daily 15-minute workouts are recommended',
  },
  {
    image: 'emoji-house.webp',
    text: 'Work out at home at your whim and convenience',
  },
  {
    image: 'no-equipment.webp',
    text: 'Jumping or using special equipment is not required',
  },
  {
    image: 'whatever-woman-emoji.webp',
    text: 'Perfect for busy women and mothers with young children',
  },
];

export const LongFirstScreenHeaderSteps = () => {
  return (
    <div className={styles.container}>
      {DATA_STEPS.map((item, index) => (
        <div key={index} className={styles.step}>
          <Image src={item.image} alt="" width={40} height={40} />
          <span className={styles.text}>{item.text}</span>
        </div>
      ))}
    </div>
  );
};
