import { ReviewsSlider } from '@features/Paywall/components/reviews-slider';
import { TpSummaryStepProps } from '@features/Quiz/PilatesOnboarding/types';
import { LongFirstScreenHeaderSteps } from '@features/Quiz/components/LongFirstScreenHeaderSteps';
import { LongFirstScreenSteps } from '@features/Quiz/components/LongFirstScreenSteps';
import { LossPlanSteps } from '@features/Quiz/components/LossPlanSteps';
import { Image } from 'src/components/common/Image';
import { Policy } from 'src/components/common/policy/policy';
import { UiKitButton } from 'src/components/common/ui-kit-button';
import styles from './LongFirstScreenB.module.scss';

const LOSS_STEPS_COLOR_DATA = ['#E3D5FF', '#F6CCF1', '#CBE5FF'];

export const LongFirstScreenB = ({ goToNextStep }: TpSummaryStepProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <h1 className={styles.title}>28 DAY CHALLENGE</h1>
          <h1 className={styles.titleMark}>PILATES WORKOUT</h1>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            src="long-first-screen.webp"
            alt="model"
            width={181}
            height={255}
            className={styles.imageModel}
          />
        </div>
      </div>

      <div className={styles.firstContainer}>
        <LongFirstScreenHeaderSteps />
        <div>
          <UiKitButton
            data-testid="long-first-screen-b-get"
            fullWidth
            onClick={() =>
              goToNextStep({
                eventName: 'onboarding_long_first_screen_b_get_tapped',
              })
            }
          >
            GET STARTED NOW
          </UiKitButton>
          <div className={styles.members}>
            <Image src="members.png" width={76} height={32} alt="" />
            <span className={styles.membersHint}>
              <b>Over 1,5 million</b> women have joined us
            </span>
          </div>
        </div>
      </div>

      <div className={styles.secondContainer}>
        <h1 className={styles.contentTitle}>
          The <b>Last Weight Loss Plan</b> You'll Ever Need
        </h1>
        <LossPlanSteps colorData={LOSS_STEPS_COLOR_DATA} />
      </div>
      <div className={styles.thirdContainer}>
        <h1 className={styles.contentTitle}>
          <b>Thousands of Women</b> Have Already Transformed Their Lives
        </h1>
        <ReviewsSlider />
        <UiKitButton
          data-testid="long-first-screen-b-start"
          className={styles.button}
          fullWidth
          onClick={() =>
            goToNextStep({
              eventName: 'onboarding_long_first_screen_b_start_journey_tapped',
            })
          }
        >
          Start Your Journey Now
        </UiKitButton>
      </div>

      <div className={styles.fourthContainer}>
        <h1 className={styles.contentTitle}>What to do to get started</h1>
        <p className={styles.subtitle}>
          Enjoy the process and get effective results!
        </p>
        <LongFirstScreenSteps />
      </div>

      <div className={styles.bottomContent}>
        <h1 className={styles.contentTitle}>
          Are you ready to get your dream body?
        </h1>
        <UiKitButton
          data-testid="long-first-screen-b-start"
          fullWidth
          onClick={() =>
            goToNextStep({
              eventName: 'onboarding_long_first_screen_b_start_quiz_tapped',
            })
          }
        >
          START QUIZ
        </UiKitButton>
        <Policy withPrefix className={styles.policy} />
      </div>
    </div>
  );
};
