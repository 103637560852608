import * as Sentry from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, useState } from 'react';

export const QueryClientCustomProvider = ({
  children,
}: { children: ReactNode }) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retryDelay: (attemptIndex: number) =>
              Math.min(1000 * 2 ** attemptIndex, 30_000),
          },
        },
        queryCache: new QueryCache({
          onError: (error: any) => {
            console.log(error);
            Sentry.captureException(error);
          },
        }),
      } as any),
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition={'bottom-left'}
      />
    </QueryClientProvider>
  );
};
