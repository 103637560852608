import { Image } from 'src/components/common/Image';
import VectorLeft from '../../../../../public/images/vector-left.svg';
import VectorRight from '../../../../../public/images/vector-right.svg';
import styles from './LongFirstScreenSteps.module.scss';

export const LongFirstScreenSteps = () => {
  return (
    <div className={styles.stepsContainer}>
      <div className={styles.stepOne}>
        <span className={styles.name}>Step 1</span>
        <span className={styles.text}>Take a quick one-minute quiz</span>
      </div>
      <VectorLeft />
      <div className={styles.stepTwo}>
        <span className={styles.name}>Step 2</span>
        <span className={styles.text}>
          Pick a workout plan that fits your fitness level and lifestyle
        </span>
      </div>
      <VectorRight />
      <div className={styles.stepThree}>
        <div className={styles.thirdContainer}>
          <span className={styles.name}>Step 3</span>
          <span className={styles.text}>
            See first visible results and feel great change after 28 days
          </span>
        </div>
        <Image src="jesica-prepaywall.webp" alt="" width={96} height={96} />
        <Image
          src="emoji-tada-prepaywall.webp"
          alt=""
          width={48}
          height={48}
          className={styles.image}
        />
      </div>
    </div>
  );
};
